import React from "react";
import "./SearchBar.css";
import ximg from "./x.png";

const SearchBar = ({
  id,
  className,
  results,
  keyword,
  showAddress,
  updateField,
  setErrMsg,
  btnClassName,
  korean_Check,
  grade
}) => {
  //renders our results using the SearchPreview component
  var updateText = (text) => {
    updateField(text, false);
  };

  var cancelSearch = () => {
    updateField("");
    setErrMsg("");
  };

  var isOpen = (results, keyword) => {
    return results.filter((value) => value.SCHUL_NM === keyword).length === 0;
  };

  var renderResults = results.map(({ SCHUL_NM, ORG_RDNMA }, index) => {
    return (
      <SearchPreview
        key={index}
        updateText={updateText}
        index={index}
        showAddress={showAddress}
        SCHUL_NM={SCHUL_NM}
        ORG_RDNMA={ORG_RDNMA}
      />
    );
  });

  return (
    <div className="auto">
      <div>
        <input
          id={id}
          className={className ? className : "search-bar"}
          placeholder={(grade === "5" || grade === "6" || grade === "7") ? "다니는 유치원" : "학교 검색"}
          value={keyword}
          onInput={(e) => korean_Check(e, "school")}
          onChange={(e) => updateField(e.target.value)}
          autoComplete="off"
        />
        <button
          onClick={() => cancelSearch()}
          className={`${btnClassName ? btnClassName : "cancel-btn"} ${
            keyword.length > 0 ? "active" : "inactive"
          }`}
        >
          <img src={ximg} alt="" />
        </button>
      </div>
      {results.length > 0 && isOpen(results, keyword) && !(grade === "5" || grade === "6" || grade === "7") ? (
        <div className="search-results">{renderResults}</div>
      ) : null}
    </div>
  );
};

//stateless component to render preview results
const SearchPreview = ({
  SCHUL_NM,
  ORG_RDNMA,
  index,
  showAddress,
  updateText,
}) => {
  return (
    <div
      onClick={() => updateText(SCHUL_NM)}
      className={`search-preview ${index === 0 ? "start" : ""}`}
    >
      <div className="first2">
        <p className="name">{SCHUL_NM}</p>
        {showAddress && <p className="sub-header">{ORG_RDNMA}</p>}
      </div>
      {/* <div className="second">
          <p className="age">{age}</p>
          <p className="sub-header">age</p>
        </div> */}
    </div>
  );
};

export default SearchBar;
