import React, { useState } from "react";
// import logo from "../image/logo.png";
// import prev from "../image/prev.png";
import Nav from "../nav";

import CheckInput from "./CheckInput";
import CheckResult from "./CheckResult";

function CheckPage() {
  const [state, setState] = useState(1);
  const [status, setStatus] = useState({});

  return (
    <>
      {Nav("", 1)}
      {state === 1 && <CheckInput setState={setState} setStatus={setStatus} />}
      {state === 2 && <CheckResult status={status} />}
    </>
  );
}

export default CheckPage;
