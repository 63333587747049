import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AcademyPage from "./views/Academy/academy";
import CmathPage from "./views/Cmath/cmath";

import { io } from "socket.io-client";

import "./App.css";
import Routers from "./Routers";

function App() {
	const socket = io();

	useEffect(() => {
		socket.connect();

		return () => socket.close();
	}, [socket]);

	return (
		<BrowserRouter>
			{socket && (
				<section
					style={{
						textAlign: "center",
						maxWidth: "600px",
						width: "100%",
						height: "100vh",
					}}
				>
					<Switch>
						<Route exact path="/academy" render={() => <AcademyPage socket={socket} />} />
						<Route exact path="/cmath" component={CmathPage} />
						{["experience", "cmathday", "explain"].map((value, index) => {
							return (
								<Route key={index} path={`/${value}`} render={() => <Routers registerType={value} socket={socket} />} />
							);
						})}
					</Switch>
				</section>
			)}
			{/* <Footer /> */}
		</BrowserRouter>
	);
}

export default App;
