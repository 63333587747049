import moment from "moment";
import React from "react";
import "./CheckResult.css";
import { useSelector } from "react-redux";

const grade_List = [
  "5세",
  "6세",
  "7세",
  "초1",
  "초2",
  "초3",
  "초4",
  "초5",
  "초6",
  "중1",
  "중2",
  "중3"
];

function CheckResult({ status }) {

    const type = useSelector((state) => state.user.registerType);

    let thema = ''
    if(type === 'experience') {
        thema = 'thema1'
    }
    else if(type === 'cmathday') {
        thema = 'thema2'
    }
    else if(type === 'explain') {
        thema = 'thema3'
    }

  return (
    <>
      <div
        className={thema}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "7vh",
        }}
      >
        <span className="wait">신청결과</span>
      </div>
      <div className="result">
        <div>
          <span>이름</span>
          <p>{status.user.name}</p>
        </div>
        {type !== 'explain' &&
            <div>
            <span>{(status.user.grade === "5"|| status.user.grade === "6" || status.user.grade === "7") ? "유치원" : "학교"}</span>
            <p>{status.user.school}</p>
            </div>
        }
        <div>
          <span>학년</span>
          <p>{grade_List[status.user.grade - 5]}</p>
        </div>
        <div>
          <span>학원</span>
          <p>{status.class.academy_Name}</p>
        </div>
        <div>
          <span>{type === "cmathday" || type === 'explain' ? "일자" : "수업일자"}</span>
          <p>{moment(status.class.date).format("YYYY.MM.DD")}</p>
        </div>
        <div>
          <span>시간</span>
          <p style={{ color: "red" }}>{status.class.time}</p>
        </div>

        {type === "cmathday" && status.user.recommendName &&
        <>
            <div>
                <span>추천한 학생</span>
                <p>{status.user.recommendName}</p>
            </div>

            <div>
                <span>{(status.user.recommendGrade === "5"|| status.user.recommendGrade === "6" || status.user.recommendGrade === "7") ? "유치원" : "학교"}</span>
                <p>{status.user.recommendSchool}</p>
            </div>

            <div>
                <span>학년</span>
                <p>{grade_List[status.user.recommendGrade - 5]}</p>
            </div>
        </>
        }
        {type !== 'explain' &&
            <div>
            <span>상태</span>
            <p style={{ color: "red" }}>{status.status}</p>
            </div>
        }
        <button
          onClick={() => (window.location.href = `/${type}`)}
          className={`nextB ${thema}`}
          style={{
            width: "100%",
            marginTop: "50px",
        }}
        >
          메인으로
        </button>
      </div>
    </>
  );
}

export default CheckResult;
