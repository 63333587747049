import axios from "axios";
import { CHANGE_PAGE, COMPARE_CODE, INSERT_CLASS, REMOVE_CODE, SELECT_ACADEMY, SET_TYPE, SET_WAIT } from "./types";

export function compareCode(dataToSubmit) {
	const request = axios.post("/api/user/confirm_code", dataToSubmit).then((response) => response.data);
	return {
		type: COMPARE_CODE,
		payload: request,
	};
}

export function removeCode(dataToSubmit) {
	const request = axios.post("/api/user/remove_code", dataToSubmit).then((response) => response.data);
	return {
		type: REMOVE_CODE,
		payload: request,
	};
}

export function setType(type) {
	return {
		type: SET_TYPE,
		registerType: type,
	};
}

export function setWait(wait) {
	return {
		type: SET_WAIT,
		wait: wait,
	};
}

export function changePage(page) {
	return {
		type: CHANGE_PAGE,
		page: page,
	};
}

export function selectAcademy(academy) {
	return {
		type: SELECT_ACADEMY,
		academy: academy,
	};
}

export function insertClass(classData) {
	return {
		type: INSERT_CLASS,
		classData: classData,
	};
}
