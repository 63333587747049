// 접수 완료 페이지
import React from "react";
import { useSelector } from "react-redux";
// import Swal from "sweetalert2";

import check from "../image/check.png";
// import next from "../image/next.png";
import "./3_Complete.css";

function Complete() {
  const wait = useSelector((state) => state.user.wait);
  const type = useSelector((state) => state.user.registerType);

  let thema = ''
  if(type === 'experience') {
      thema = 'thema1'
  }
  else if(type === 'cmathday') {
      thema = 'thema2'
  }
    else if(type === 'explain') {
        thema = 'thema3'
    }

  return (
    <>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "0 16px",
        }}
      >
        <img
          src={check}
          style={{ width: 70, height: 70, marginTop: "54px" }}
          alt="check"
        />
        <b
            className={`point ${thema}`}
        >
            {wait === "대기" && <span style={{ color: "red" }}>대기자</span>}{" "}
            신청이 완료되었습니다.
        </b>

        {wait === "확정" && (
          <>
            <p
              className="guide"
              style={{
                color: "#878787",
                fontSize: "15px",
                margin: "13px 0 6px 0",
              }}
            >
            </p>
            <p
              className="guide"
              style={{
                color: "#878787",
                fontSize: "15px",
                marginBottom: "61px",
              }}
            >
              신청시간 변동 및 기타 문의사항은 신청하신 학원으로 연락해주세요.
            </p>
          </>
        )}
        {wait === "대기" && (
          <>
            <p className="guide" style={{ color: "#878787", fontSize: "15px" }}>
                신청 사항이 확정되면 신청하신 학원에서 안내를 드리며,
            </p>
            <p
              className="guide"
              style={{
                color: "#878787",
                fontSize: "15px",
                marginBottom: "6px",
              }}
            >
                '메인 {">"} 접수 조회'에서도 대기 순번의 확정 여부를 확인할 수 있습니다.
            </p>
            <p
              className="guide"
              style={{
                color: "#878787",
                fontSize: "15px",
                marginBottom: "61px",
              }}
            >
              기타 문의사항은 해당 학원으로 연락 주세요.
            </p>
          </>
        )}

        <button
          className={`nextB ${thema}`}
          style={{
            width: "100%",
        }}
          onClick={() => (window.location.href = `/${type}`)}
        >
          확인
        </button>
      </div>
    </>
  );
}

export default Complete;
