// 접수신청 전체 관리 페이지
import React, { useState } from "react";
import Complete from "./3_Complete";
import Register from "./1_Register";
// import logo from "../image/logo.png";
// import prev from "../image/prev.png";

import next from "../image/next.png";

import Nav from "../nav";

function RegisterPage({ socket, type }) {
  const [state, setState] = useState(2);

  let thema = ''
  if(type === 'experience') {
      thema = 'thema1'
  }
  else if(type === 'cmathday') {
      thema = 'thema2'
  }
    else if(type === 'explain') {
        thema = 'thema3'
    }

  return (
    <>
      {Nav("", state, setState)}

      <div
        className={thema}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "7vh",
        }}
      >
        <span className="wait">01 학원선택</span>
        <img src={next} style={{ marginTop: "0" }} alt="next" />
        <span className={state === 2 ? "now" : "wait"}>02 정보입력</span>
        <img src={next} style={{ marginTop: "0" }} alt="next" />
        <span className={state === 3 ? "now" : "wait"}>03 신청완료</span>
      </div>
      {state === 2 && <Register setState={setState} socket={socket} />}
      {state === 3 && <Complete />}
    </>
  );
}

export default RegisterPage;
