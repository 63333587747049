import React, { forwardRef, useEffect, useLayoutEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";

import "./academy.css";

import close_Img from "./image/close.png";
import setting_Img from "./image/setting.png";
import add_Img from "./image/add.png";
import del_Img from "./image/del.png";
import edit_Img from "./image/edit.png";
import logo_Img from "./image/logo.png";
import addClass_Img from "./image/addclass.png";

// 학교 검색
import SearchBar from "../SearchBar/SearchBar";
// 학교 데이터
import newdata from "../SearchBar/schoolList.json";
import moment from "moment";

registerLocale("ko", ko);

const grade_List = [
	"5세",
	"6세",
	"7세",
	"초1",
	"초2",
	"초3",
	"초4",
	"초5",
	"초6",
	//   "유아(Wini)",
	//   "초등저학년(NC)",
	//   "초등고학년(AP)",
	"중1",
	"중2",
	"중3",
];

function Academy({ socket }) {
	const [type, Set_type] = useState("experience");

	const [totalData, setTotalData] = useState([]);
	const [setup_List, Set_setup_List] = useState([]);

	const [canShow, setCanShow] = useState(false);
	const [isLoading, setisLoading] = useState(false);

	const [day_List, Set_day_List] = useState([]);
	const [student_List, Set_student_List] = useState([]);

	const [select_Day, Set_select_Day] = useState(null);
	const [select_Class, Set_select_Class] = useState(null);
	const [select_Class_ID, Set_select_Class_ID] = useState("");

	// 학교 검색 결과
	const [results, setResults] = useState([]);

	// 학교 이름
	const [school, setSchool] = useState("");

	// 에러 메시지
	const [schoolErr, setSchoolErr] = useState("");

	// 학교 결과 종류
	const [whatSchool, setWhatSchool] = useState("");

	// 수정 쪽 학교 검색 결과
	const [mresults, setmResults] = useState([]);

	// 수정 쪽 학교 이름
	const [mschool, setmSchool] = useState("");

	// 수정 쪽 에러 메시지
	const [mschoolErr, setmSchoolErr] = useState("");

	const [mode, Set_mode] = useState("registration");
	const [academy_Data, set_Academy_Data] = useState("");

	const [day, setDay] = useState(null);

    const [experienceUse, Set_experienceUse] = useState("0")
    const [cmathdayUse, Set_cmathdayUse] = useState("0")
    const [explainUse, Set_explainUse] = useState("0")

	const history = useHistory();

	useEffect(() => {
		const script = document.createElement("script");
		script.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
		document.head.appendChild(script);

		async function checkData() {
			let authData = "";
			if (history.location.state) authData = history.location.state;
			else {
				await Axios.post("/api/damo").then((res) => {
					if (res.data.success) authData = res.data.BranchName;
				});
				// authData = "강남 대치점";
			}
			if (authData === "" || authData === "서초 본사") {
				alert("잘못된 접근입니다.");
				history.goBack();
			} else {
				await Axios.post("/api/academy/academy_Check", {
					academy_Name: authData,
				}).then(async (res) => {
					if(!res.data) {
						alert("허용되지 않은 학원입니다.");
						history.goBack();
					}
                    else {
                        if(res.data.experience_use === "0" && res.data.cmathday_use === "1") {
                            Set_type("cmathday")
                        }
                        else if(res.data.experience_use === "0" && res.data.cmathday_use === "0" && res.data.explain_use === "1") {
                            Set_type("explain")
                        }
                        Set_experienceUse(res.data.experience_use)
                        Set_cmathdayUse(res.data.cmathday_use)
                        Set_explainUse(res.data.explain_use)

                        setCanShow(true);
                        await Axios.post("/api/academy/setup_Load", {
                            academy: authData,
                            type: type,
                        }).then((res) => {
                            setTotalData(res.data);
                            document.getElementById("master_View").style.display = "block";
                        });
                        set_Academy_Data(authData);
                    }
				});
			}
		}
		checkData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setting(totalData, type);
	}, [totalData, type]);

	useEffect(() => {
		if (academy_Data === "") return;
		socket.emit("joinRoom", academy_Data);
		socket.on("FromAca", (value) => setTotalData(value));
	}, [socket, academy_Data]);

	useLayoutEffect(() => {
		if (setup_List.length === 0 || day_List.length === 0) return;

		if (!["experience", "cmathday", "explain"].includes(type) || day_List.filter((v) => v.type === type).length === 0) {
			Set_student_List([]);
			Set_select_Day(null);
			Set_select_Class(null);
			Set_select_Class_ID("");
			return;
		}

		let dateChk = day_List.some((v) => v.date === select_Day);

		let d = day_List.filter((v) => (dateChk ? v.date === select_Day : v.type === type))[0].date;
		let t = setup_List.filter((x) => x.date === d)[0].time;
		let k = setup_List.filter((x) => x.date === d && x.time === t)[0];
		let g = k.grade;
		let id = k._id;
		let c = `${t}_${g}`;

		if (!dateChk) {
			Set_select_Day(d);
			Set_select_Class(c);
			Set_select_Class_ID(id);
		}

		let j = setup_List.filter((x) => x._id === id)[0];
		Set_student_List(j.member);
	}, [setup_List, day_List, select_Day, type]);

	// 여기서 부터 학교 검색 부분
	const data = newdata;

	const matchName = (name, keyword) => {
		if (keyword === "") return false;
		return name.indexOf(keyword) > -1;
	};

	const onSearch = (text) => {
		//check to see if we found a match, if so, add it to results
		var result = data.filter((item) => true === matchName(item.SCHUL_NM, text));
		//update state changes
		if (whatSchool === "insert") setResults(result);
		if (whatSchool === "modify") setmResults(result);
	};

	const updateField = (value, update = true) => {
		if (update) onSearch(value);
		const rule = /[^ㄱ-ㅎㅏ-ㅣ가-힝]/g;
		if (rule.exec(value) !== null) document.getElementById("schoolAlert").style.display = "flex";
		let result = value.replace(rule, "");
		if (whatSchool === "insert") setSchool(result);
		if (whatSchool === "modify") setmSchool(result);
	};
	function search_korean_Check(e) {
		const rule = /[^ㄱ-ㅎㅏ-ㅣ가-힝]/g;
		let text = "";
		if (rule.exec(e.target.value) !== null) {
			e.target.value = e.target.value.replace(rule, "");
			text = "*한글로 입력해주세요.";
		}
		if (whatSchool === "insert") setSchoolErr(text);
		if (whatSchool === "modify") setmSchoolErr(text);
	}

	// 여기까지 학교 검색 부분

	function korean_Check(e) {
		if (e.target.nextSibling.className === "alert") {
			e.target.nextSibling.remove();
		}

		const rule = /[^ㄱ-ㅎㅏ-ㅣ가-힝]/g;
		if (rule.exec(e.target.value) !== null) {
			e.target.value = e.target.value.replace(rule, "");
			if (e.target.nextSibling.className !== "alert") {
				let div = document.createElement("div");
				div.className = "alert";
				div.innerText = "*한글로 입력해 주세요.";
				e.target.after(div);
			}
		}
	}

	function number_Check(e) {
		if (e.target.nextSibling.className === "alert") {
			e.target.nextSibling.remove();
		}

		const rule = /[^0-9]/g;
		if (rule.exec(e.target.value) !== null) {
			e.target.value = e.target.value.replace(rule, "");
			if (e.target.nextSibling.className !== "alert") {
				let div = document.createElement("div");
				div.className = "alert";
				div.innerText = "*숫자로 입력해 주세요.";
				e.target.after(div);
			}
		}
	}

	const setting = (lists, type) => {
		let data = lists.filter((x) => x.type === type);
		if (data.length !== 0) {
			let day = [];
			for (let i = 0; i < data.length; i++) {
				let obj = {
					type: data[i].type,
					date: data[i].date,
				};
				if (!day.some((v) => JSON.stringify(v) === JSON.stringify(obj))) {
					day.push(obj);
				}
			}
			Set_setup_List(data);
			Set_day_List(day);
		} else {
			Set_setup_List([]);
			Set_day_List([]);
		}
		setisLoading(false);
	};

	function reset() {
		document.getElementById("id").value = "";
		setDay(null);
		document.getElementById("time").value = "";
		document.getElementById("limit").value = "";
		document.getElementById("name").value = "";
		document.getElementById("add_Address").value = "";
		document.getElementById("student_grade").value = 5;
		document.getElementById("phone").value = "";
		setSchool("");
		setmSchool("");
		setSchoolErr("");
		setmSchoolErr("");
		setResults([]);
		setmResults([]);
	}

	function registration() {
		const classDay = document.getElementById("class").value.split("_")[0];
		const classTime = document.getElementById("class").value.split("_")[1];
		const classGrade = document.getElementById("class").value.split("_")[2];

		const name = document.getElementById("name").value;
		const address = document.getElementById("add_Address").value;
		const school = document.getElementById("school").value;
		const grade = document.getElementById("student_grade").value;
		const phone = document.getElementById("phone").value;

		if (name === "") {
			alert("이름을 입력해 주세요.");
		} else if (school === "") {
			alert("학교를 입력해 주세요.");
		} else if (phone === "") {
			alert("연락처를 입력해 주세요.");
		} else {
			if (window.confirm("등록하시겠습니까?")) {
				Axios.post("/api/academy/student_Save", {
					class_Day: classDay,
					class_Time: classTime,
					class_Grade: classGrade,
					student_Name: name,
					student_Address: address,
					student_School: school,
					student_Grade: grade,
					student_Phone: phone,
					academy: academy_Data,
				}).then((res) => {
					setTotalData(res.data);
					socket.emit("getData", academy_Data);
					reset();
				});
			}
		}
	}

	function setup_Save(set) {
		const id = document.getElementById("id").value;
		const time = document.getElementById("time").value;
		let grade = "";
		if (type === "experience") {
			grade = document.getElementById("class_grade").value;
		}
		const limit = document.getElementById("limit").value;

		if (day === "") {
			alert("날짜를 설정하세요.");
		} else if (time === "") {
			alert("시간을 설정하세요.");
		} else if (limit === "") {
			alert("인원 수를 설정하세요.");
		} else {
			if (set === "registration") {
				if (window.confirm("등록하시겠습니까?")) {
					Axios.post("/api/academy/setup_Save", {
						type: type,
						academy: academy_Data,
						date: moment(day).format("YYYY-MM-DD"),
						time: time,
						grade: grade,
						limit: limit,
					}).then((res) => {
						if (res.data === "exist") {
							alert("이미 등록되었습니다.");
						} else {
							setisLoading(true);
							setTotalData(res.data);
							socket.emit("getData", academy_Data);
							reset();
						}
					});
				}
			} else if (set === "modify") {
				if (window.confirm("수정하시겠습니까?")) {
					Axios.post("/api/academy/setup_Modify", {
						id: id,
						date: moment(day).format("YYYY-MM-DD"),
						time: time,
						grade: grade,
						limit: limit,
						academy: academy_Data,
					}).then((res) => {
						if (!res.data) return alert("해당 날짜에 같은 시간, 같은 학년으로 등록된 반이 있습니다!");
						setisLoading(true);
						Set_select_Day(moment(day).format("YYYY-MM-DD"));
						Set_select_Class(`${time}_${grade}`);
						socket.emit("getData", academy_Data);
						setTotalData(res.data);
					});
				}
			}
		}
	}

	function day_Click(value) {
		const day = value;
		const time = setup_List.filter((x) => x.date === value)[0].time;
		const grade = setup_List.filter((x) => x.date === value && x.time === time)[0].grade;
		Set_select_Day(day);
		Set_select_Class(`${time}_${grade}`);
		Set_select_Class_ID(setup_List.filter((x) => x.date === day && x.time === time && x.grade === grade)[0]._id);
		Set_student_List(setup_List.filter((x) => x.date === day && x.time === time && x.grade === grade)[0].member);
	}

	function class_Click(t, g, id) {
		const time = t;
		const grade = g;
		Set_select_Class(`${time}_${grade}`);
		Set_student_List(setup_List.filter((x) => x._id === id)[0].member);
	}

	function div_Move(target) {
		target.style.zIndex = 5;
		const a = target.offsetLeft - window.event.pageX;
		const b = target.offsetTop - window.event.pageY;
		window.onmousemove = function () {
			target.style.left = a + window.event.pageX + "px";
			target.style.top = b + window.event.pageY + "px";
			target.onmouseup = function () {
				target.style.zIndex = 1;
				window.onmousemove = null;
			};
		};
	}

	function popup_Close() {
		const popup = document.getElementsByName("popup");
		for (let i = 0; i < popup.length; i++) {
			popup[i].style.display = "none";
		}
	}

	function popup_Switch(index) {
		popup_Close();
		if (index === 1) setWhatSchool("insert");
		else if (index === 2) setWhatSchool("modify");
		else setWhatSchool("");
		document.getElementsByName("popup")[index].style.display = "block";
	}

	function top_Bar(text) {
		return (
			<div className="bar" onMouseDown={(e) => div_Move(e.target.parentNode)}>
				<span style={{ color: "white", pointerEvents: "none" }}>{text}</span>
				<img
					alt="close"
					src={close_Img}
					style={{ width: "auto", height: "auto", cursor: "pointer" }}
					onClick={() => popup_Close()}
				/>
			</div>
		);
	}

	function setup_Remove(value) {
		if (window.confirm("삭제하시겠습니까?")) {
			if (value.member.length !== 0) {
				alert("학생이 등록되어 있으므로 삭제할 수 없습니다.");
			} else {
				Axios.post("/api/academy/setup_Remove", {
					id: value._id,
					academy: academy_Data,
				}).then((res) => {
					setTotalData(res.data);
					socket.emit("getData", academy_Data);
					setup_Modify("cancel");
				});
			}
		}
	}

	function setup_Modify(set, value, index) {
		const class_List = document.getElementsByName("classList");
		for (let i = 0; i < class_List.length; i++) {
			class_List[i].style.backgroundColor = "#C2C2C2";
		}
		if (set === "modify") {
			document.getElementById("id").value = value._id;
			setDay(moment(value.date).toDate());
			document.getElementById("time").value = value.time;
			document.getElementById("limit").value = value.limit;
			class_List[index].style.backgroundColor = "white";
			Set_mode("modify");
		} else if (set === "cancel") {
			document.getElementById("id").value = "";
			setDay(null);
			document.getElementById("time").value = "";
			document.getElementById("limit").value = "";
			Set_mode("registration");
		}
	}

	const CustomInput = forwardRef((props, ref) => {
		return (
			<input
				ref={ref}
				className="date-input"
				onClick={props.onClick}
				placeholder="날짜 선택"
				value={props.value}
				type="text"
				readOnly={true}
			/>
		);
	});

	function setup_Div() {
		return (
			<div
				id="setup_Div"
				className="popup"
				name="popup"
				onClick={(e) => {
					let a = e.target;
					while (true) {
						a = a["parentNode"];
						if (a.id === "time_Setup_Div") {
							break;
						} else if (a.id === "setup_Div") {
							document.getElementById("time").nextSibling.style.display = "none";
							break;
						}
					}
				}}
			>
				{top_Bar("시간 설정/인원설정")}

				<div style={{ position: "relative", display: "flex", height: "420px" }}>
					<div
						className="scroll2"
						style={{
							position: "relative",
							height: "380px",
							width: "230px",
							backgroundColor: "#C2C2C2",
							boxSizing: "border-box",
							marginRight: "35px",
						}}
					>
						<div
							style={{
								position: "absolute",
								width: "100%",
								textAlign: "center",
								marginTop: "50px",
							}}
						>
							오른쪽에서 일정을<br></br>등록해주세요.
						</div>
						<div
							style={{
								position: "absolute",
								width: "100%",
								height: "100%",
								backgroundColor: "#C2C2C2",
							}}
						>
							{setup_List.map((value, index) => {
								return (
									<div
										key={index}
										name="classList"
										className="classList"
										style={{
											width: "100%",
											height: "53px",
											borderBottom: "1px solid #A6A6A6",
											position: "relative",
											cursor: "pointer",
										}}
										onClick={() => setup_Modify("modify", value, index)}
									>
										<img
											className="delIcon"
											alt="del"
											src={del_Img}
											style={{
												width: "auto",
												height: "auto",
												cursor: "pointer",
												position: "absolute",
												right: "10px",
												top: "10px",
												zIndex: "999",
											}}
											onClick={() => setup_Remove(value)}
										/>
										<div
											style={{
												boxSizing: "border-box",
												paddingTop: "3px",
												fontSize: "15px",
												fontWeight: "bold",
												textAlign: "left",
												paddingLeft: "10px",
											}}
										>
											{value.date} / {value.time}
										</div>
										<div
											style={{
												textAlign: "left",
												paddingLeft: "10px",
												color: "#949494",
											}}
										>
											{type === "experience" ? `${grade_List[value.grade - 5]} / ${value.limit}명` : `${value.limit}명`}
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="addClass" onClick={() => setup_Modify("cancel")}>
						<img src={addClass_Img} alt="" />
					</div>

					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							paddingTop: "72px",
						}}
					>
						<input type="hidden" id="id"></input>
						{/* <input
              type="date"
              readOnly
              id="day"
              className="input_Text"
              style={{
                fontFamily: "Noto Sans KR",
                fontWeight: "400",
              }}
            ></input> */}
						<DatePicker
							id="day"
							customInput={<CustomInput />}
							locale="ko"
							dateFormat={"yyyy-MM-dd"}
							selected={day}
							onChange={(v) => setDay(v)}
							style={{
								margin: 0,
								width: "255px",
								fontFamily: "Noto Sans KR",
								fontWeight: "400",
							}}
						/>
						{time_Setup()}

						{type === "experience" && (
							<select id="class_grade" className="input_Text">
								{grade_List.map((value, index) => {
									return (
										<option key={index} value={index + 5}>
											{value}
										</option>
									);
								})}
							</select>
						)}
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: type === "experience" ? "30px" : "100px",
								width: "100%",
							}}
						>
							<input
								type="text"
								id="limit"
								maxLength={type === 'explain' ? 3 : 2}
								className="input_Text"
								autoComplete="off"
								placeholder="인원"
								style={{
									fontSize: "17px",
									height: "50px",
									lineHeight: "50px",
									width: "230px",
									marginBottom: "0px",
									marginRight: "5px",
								}}
								onInput={(e) => number_Check(e)}
							></input>
							<div
								style={{
									// position: "absolute",
									fontSize: "17px",
									// top: "262px",
									// right: "30px",
								}}
							>
								명
							</div>
						</div>
						{mode === "registration" ? (
							<div className="button1" onClick={() => setup_Save("registration")}>
								등록
							</div>
						) : (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<div className="button1" onClick={() => setup_Save("modify")}>
									수정
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	function day_Div() {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: "50px",
					/*justifyContent: day_List.length > 6 ? null : "center",*/
					width: "1024px",
					/*overflowX: "auto",*/
				}}
			>
				{day_List.map((D_value, D_index) => {
					return (
						<div key={D_index}>
							<div
								className="tab"
								style={{
									borderBottom: D_value.date === select_Day ? "2px solid #E60012" : "2px solid #ccc",
									color: D_value.date === select_Day ? "#E60012" : "#959595",
								}}
								onClick={() => day_Click(D_value.date)}
							>
								{D_value.date}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	function class_Div() {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					width: "1024px",
					height: "150px",
					overflowX: "auto",
				}}
			>
				{setup_List
					.filter((x) => x.date === select_Day)
					.map((value, index) => {
						return (
							<div
								key={index}
								style={{
									padding: "auto",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									cursor: "pointer",
									margin: "auto",
								}}
								onClick={() => {
									Set_select_Class_ID(value._id);
									class_Click(value.time, value.grade, value._id);
								}}
							>
								<div
									style={{
										width: "max-content",
										padding: "5px 30px 5px 30px",
										margin: "10px",
										boxSizing: "border-box",
										borderRadius: "30px",
										color: "white",
										backgroundColor: value._id === select_Class_ID ? "red" : "gray",
									}}
								>
									{type === "experience" ? `${value.time} (${grade_List[value.grade - 5]})` : `${value.time}`}
								</div>
								<div>
									{value.member.length} / {value.limit}
								</div>
							</div>
						);
					})}
			</div>
		);
	}

	function student_Delete(user) {
		if (window.confirm("삭제하시겠습니까?")) {
			Axios.post("/api/academy/student_Remove", {
				user: user,
				classid: select_Class_ID,
				academy: academy_Data,
			}).then(async (res) => {
				await setTotalData(res.data);
				await socket.emit("getData", academy_Data);
			});
		}
	}

	function student_Modify() {
		const id = document.getElementById("modify_ID").value;
		const name = document.getElementById("modify_Name").value;
		const address = document.getElementById("modify_Address").value;
		const school = document.getElementById("modify_School").value;

		let grade = "";
		if (type === "experience") {
			grade = document.getElementById("modify_Grade").value;
		}
		const phone = document.getElementById("modify_Phone").value;
		const pre_Class = document.getElementById("modify_Pre_Class").value;
		const M_class = document.getElementById("modify_Class").value;

		if (window.confirm("수정하시겠습니까?")) {
			Axios.post("/api/academy/student_Modify", {
				id: id,
				name: name,
				address: address,
				school: school,
				grade: grade,
				phone: phone,
				academy: academy_Data,
				pre_Class: pre_Class === M_class ? undefined : pre_Class,
				M_class: pre_Class === M_class ? undefined : M_class,
			}).then((res) => {
				setTotalData(res.data);
				if (pre_Class !== M_class) socket.emit("getData", academy_Data);
				popup_Close();
			});
		}
	}

	async function modify_Setup(value) {
		await setmSchool(value.school);
		document.getElementById("modify_ID").value = value._id;
		document.getElementById("modify_Name").value = value.name;
		document.getElementById("modify_Address").value = value.address;
		// document.getElementById("modify_School").value = value.school;
		if (type === "experience") {
			document.getElementById("modify_Grade").value = value.grade;
		}
		document.getElementById("modify_Phone").value = value.phone;
		document.getElementById("modify_Pre_Class").value = select_Class_ID;
		document.getElementById("modify_Class").value = select_Class_ID;
		popup_Switch(2);
	}

	function modify_Div() {
		return (
			<div className="popup popup2" name="popup">
				{top_Bar("수정")}

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						marginTop: "22px",
					}}
				>
					<input type="hidden" id="modify_ID"></input>
					<input type="hidden" id="modify_Pre_Class"></input>
					<input
						type="text"
						className="input_Text"
						style={{ marginBottom: "30px" }}
						id="modify_Name"
						placeholder="학생 이름"
						autoComplete="off"
						onInput={(e) => korean_Check(e)}
					></input>

					{address_Div2()}
					{/* <input
            type="text"
            className="input_Text"
            style={{ marginBottom: "30px" }}
            id="modify_School"
            placeholder="학교명"
            autoComplete="off"
            onInput={(e) => korean_Check(e)}
          ></input> */}
					<SearchBar
						id="modify_School"
						className="input_Text"
						results={mresults}
						keyword={mschool}
						showAddress={false}
						updateField={updateField}
						btnClassName="cancel-btn2"
						setErrMsg={setmSchoolErr}
						korean_Check={search_korean_Check}
					/>
					<div
						style={{
							height: "14px",
							color: "red",
							width: "255px",
							marginBottom: "10px",
							fontSize: "12px",
							textAlign: "left",
						}}
					>
						{mschoolErr}
					</div>
					{type === "experience" && (
						<select id="modify_Grade" className="input_Text" style={{ marginBottom: "30px" }}>
							{grade_List.map((value, index) => {
								return (
									<option key={index} value={index + 5}>
										{value}
									</option>
								);
							})}
						</select>
					)}
					<input
						type="text"
						className="input_Text"
						id="modify_Phone"
						placeholder="연락처"
						style={{ marginBottom: "30px" }}
						autoComplete="off"
						onInput={(e) => number_Check(e)}
					></input>
					<select className="input_Text" id="modify_Class">
						{setup_List.map((value, index) => {
							return (
								<option key={index} value={value._id}>
									{value.date} / {value.time} ({grade_List[value.grade - 5]})
								</option>
							);
						})}
					</select>
				</div>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginTop: "50px",
					}}
				>
					<div className="button1" onClick={() => student_Modify()}>
						확인
					</div>
					<div
						className="button1"
						style={{
							backgroundColor: "white",
							color: "black",
							border: "1px solid #CCCCCC",
							boxSizing: "border-box",
						}}
						onClick={() => popup_Close()}
					>
						취소
					</div>
				</div>
			</div>
		);
	}

	function list_Div() {
		return (
			<table
				style={{
					backgroundColor: "#F2F2F2",
					height: student_List.length === 0 ? "500px" : null,
				}}
			>
				<thead>
					<tr
						style={{
							display: "flex",
							height: "40px",
							backgroundColor: "rgb(150, 150, 150)",
						}}
					>
						<td className="cell_Head" style={{ width: "120px", paddingLeft: "20px" }}>
							번호
						</td>
						<td className="cell_Head" style={{ width: "200px" }}>
							이름
						</td>
						<td className="cell_Head" style={{ width: "140px" }}>
							학년
						</td>
						<td className="cell_Head" style={{ width: "200px" }}>
							연락처
						</td>
						<td className="cell_Head" style={{ width: "200px" }}>
							학교
						</td>
						<td className="cell_Head" style={{ width: "300px" }}>
							주소
						</td>
						<td className="cell_Head" style={{ width: "60px" }}>
							수정
						</td>
						<td className="cell_Head" style={{ width: "60px" }}>
							삭제
						</td>
					</tr>
				</thead>
				<tbody>
					{!isLoading &&
						student_List !== null &&
						student_List.map((value, index) => {
							return (
								<tr
									key={index}
									style={{
										display: "flex",
										height: "40px",
										backgroundColor: index % 2 === 0 ? "#F2F2F2" : "#E5E5E5",
									}}
								>
									<td
										className="cell_Body"
										style={{
											position: "relative",
											width: "120px",
											paddingLeft: "20px",
										}}
									>
										{index + 1}
										{index + 1 >
											setup_List.filter(
												(x) =>
													String(x.date) === String(select_Day) &&
													String(x.time) === String(select_Class.split("_")[0]) &&
													String(x.grade) === String(select_Class.split("_")[1])
											)[0]?.limit && (
											<div
												style={{
													background: "#E60012",
													color: "#fff",
													padding: "0 10px",
													borderRadius: "100px",
													marginLeft: "7px",
												}}
											>
												대기
											</div>
										)}
									</td>
									<td className="cell_Body" style={{ width: "200px" }}>
										{value.name}
									</td>
									<td className="cell_Body" style={{ width: "140px" }}>
										{grade_List[value.grade - 5]}
									</td>
									<td className="cell_Body" style={{ width: "200px" }}>
										{value.phone}
									</td>
									<td className="cell_Body" style={{ width: "200px" }}>
										{value.school}
									</td>
									<td className="cell_Body" style={{ width: "300px" }}>
										{value.address}
									</td>
									<td className="cell_Body" style={{ width: "60px" }}>
										<img
											alt="edit"
											src={edit_Img}
											style={{
												width: "auto",
												height: "auto",
												cursor: "pointer",
											}}
											onClick={() => modify_Setup(value)}
										/>
									</td>
									<td className="cell_Body" style={{ width: "60px" }}>
										<img
											alt="del"
											src={del_Img}
											style={{
												width: "auto",
												height: "auto",
												cursor: "pointer",
											}}
											onClick={() => student_Delete(value)}
										/>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		);
	}

	function address_Div() {
		return (
			<div style={{ position: "relative", width: "255px", marginBottom: "24px" }}>
				<input
					type="text"
					id="add_Address"
					readOnly={true}
					placeholder="주소 검색"
					onClick={(e) => {
						if (e.target.nextSibling.style.display === "none") {
							sample3_execDaumPostcode("add_Address");
						}
					}}
					style={{ width: "100%" }}
				></input>

				<div
					style={{
						display: "none",
						border: "1px solid",
						width: "400px",
						height: "300px",
						margin: "5px 0",
						position: "absolute",
						zIndex: 10,
					}}
				>
					<img
						src="//t1.daumcdn.net/postcode/resource/images/close.png"
						id="btnFoldWrap"
						style={{
							cursor: "pointer",
							position: "absolute",
							right: "0px",
							top: "-1px",
							zIndex: "1",
						}}
						onClick={(e) => (e.target.parentNode.style.display = "none")}
						alt="접기 버튼"
					></img>
				</div>
			</div>
		);
	}

	function address_Div2() {
		return (
			<div style={{ position: "relative", width: "255px", marginBottom: "24px" }}>
				<input
					type="text"
					id="modify_Address"
					readOnly={true}
					placeholder="주소 검색"
					onClick={(e) => {
						if (e.target.nextSibling.style.display === "none") {
							sample3_execDaumPostcode("modify_Address");
						}
					}}
					style={{ width: "100%" }}
				></input>

				<div
					style={{
						display: "none",
						border: "1px solid",
						width: "400px",
						height: "300px",
						margin: "5px 0",
						position: "absolute",
						zIndex: 10,
					}}
				>
					<img
						src="//t1.daumcdn.net/postcode/resource/images/close.png"
						id="btnFoldWrap"
						style={{
							cursor: "pointer",
							position: "absolute",
							right: "0px",
							top: "-1px",
							zIndex: "1",
						}}
						onClick={(e) => (e.target.parentNode.style.display = "none")}
						alt="접기 버튼"
					></img>
				</div>
			</div>
		);
	}

	function sample3_execDaumPostcode(what) {
		const aaa = document.getElementById(what).nextSibling;
		new window.daum.Postcode({
			oncomplete: function (data) {
				var addr = "";
				if (data.userSelectedType === "R") {
					addr = data.roadAddress;
				} else {
					addr = data.jibunAddress;
				}
				document.getElementById(what).value = addr;
				aaa.style.display = "none";
			},
			width: "100%",
			height: "100%",
		}).embed(aaa);
		aaa.style.display = "block";
	}

	function registration_Div() {
		return (
			<div className="popup popup2" name="popup">
				{top_Bar("학생 등록")}

				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						marginTop: "22px",
					}}
				>
					<input
						type="text"
						id="name"
						style={{ marginBottom: "30px" }}
						maxLength={5}
						className="input_Text"
						placeholder="학생 이름"
						autoComplete="off"
						onInput={(e) => korean_Check(e)}
					></input>

					{address_Div()}

					{/* <input
            type="text"
            id="school"
            className="input_Text"
            style={{ marginBottom: "30px" }}
            placeholder="학교명"
            autoComplete="off"
            onInput={(e) => korean_Check(e)}
          ></input> */}
					<SearchBar
						id="school"
						className="input_Text"
						results={results}
						keyword={school}
						showAddress={false}
						updateField={updateField}
						btnClassName="cancel-btn2"
						setErrMsg={setSchoolErr}
						korean_Check={search_korean_Check}
					/>
					<div
						style={{
							height: "20px",
							color: "red",
							width: "255px",
							marginBottom: "10px",
							fontSize: "13px",
							textAlign: "left",
						}}
					>
						{schoolErr}
					</div>
					<select id="student_grade" className="input_Text" style={{ marginBottom: "30px" }}>
						{grade_List.map((value, index) => {
							return (
								<option key={index} value={index + 5}>
									{value}
								</option>
							);
						})}
					</select>
					<input
						type="text"
						id="phone"
						maxLength={11}
						className="input_Text"
						style={{ marginBottom: "30px" }}
						placeholder="연락처"
						autoComplete="off"
						onInput={(e) => number_Check(e)}
					></input>
					<select className="input_Text" id="class">
						{setup_List.map((value, index) => {
							return (
								<option key={index} value={`${value.date}_${value.time}_${value.grade}`}>
									{type === "experience"
										? `${value.date} / ${value.time} (${grade_List[value.grade - 5]})`
										: `${value.date} / ${value.time}`}
								</option>
							);
						})}
					</select>
				</div>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div className="button1" onClick={() => registration()}>
						확인
					</div>
					<div
						className="button1"
						style={{
							backgroundColor: "white",
							color: "black",
							border: "1px solid #CCCCCC",
							boxSizing: "border-box",
						}}
						onClick={() => popup_Close()}
					>
						취소
					</div>
				</div>
			</div>
		);
	}

	function time_Setup2() {
		let ampm = document.querySelector('input[name="ampm"]:checked');
		let hour = document.querySelector('input[name="hour"]:checked');
		let min = document.querySelector('input[name="min"]:checked');

		const time = document.getElementById("time");
		if (ampm !== null && hour !== null && min !== null) {
			time.value = `${ampm.value} ${hour.value}:${min.value}`;
		}
		time.nextSibling.style.display = "none";
	}

	function time_Setup() {
		return (
			<div id="time_Setup_Div" style={{ position: "relative" }}>
				<input
					type="text"
					id="time"
					placeholder="시간 선택"
					readOnly={true}
					onClick={(e) => (e.target.nextSibling.style.display = "block")}
					autoComplete="off"
				></input>
				<div
					style={{
						position: "absolute",
						display: "none",
						backgroundColor: "white",
						border: "1px solid #ccc",
						width: "255px",
						marginTop: "-11px",
						boxShadow: "2px 2px 4px rgba(0,0,0,0.1)",
						zIndex: 10,
					}}
				>
					<div style={{ display: "flex", height: "150px" }}>
						<div
							style={{
								overflowY: "auto",
								width: "33.3333%",
								borderRight: "1px solid #ccc",
							}}
						>
							{Array.from(["오전", "오후"]).map((value, index) => {
								return (
									<label
										key={index}
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
											height: "30px",
										}}
									>
										<input type="radio" className="time_Setup_Radio" name="ampm" value={value}></input>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
												height: "100%",
											}}
										>
											{value}
										</div>
									</label>
								);
							})}
						</div>
						<div
							className="scroll"
							style={{
								overflowY: "auto",
								width: "33.3333%",
								borderRight: "1px solid #ccc",
							}}
						>
							{Array.from({ length: 12 }).map((value, index) => {
								return (
									<label
										key={index}
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
											height: "30px",
										}}
									>
										<input
											type="radio"
											className="time_Setup_Radio"
											name="hour"
											value={index + 1 < 10 ? `0${index + 1}` : index + 1}
										></input>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
												height: "100%",
											}}
										>
											{index + 1 < 10 ? `0${index + 1}` : index + 1}
										</div>
									</label>
								);
							})}
						</div>
						<div className="scroll" style={{ overflowY: "auto", width: "33.3333%" }}>
							{Array.from({ length: 12 }).map((value, index) => {
								return (
									<label
										key={index}
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
											height: "30px",
										}}
									>
										<input
											type="radio"
											className="time_Setup_Radio"
											name="min"
											value={index * 5 < 10 ? `0${index * 5}` : index * 5}
										></input>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
												height: "100%",
											}}
										>
											{index * 5 < 10 ? `0${index * 5}` : index * 5}
										</div>
									</label>
								);
							})}
						</div>
					</div>
					<div
						style={{
							height: "45px",
							lineHeight: "45px",
							borderTop: "1px solid #ccc",
							background: "rgb(155, 155, 155)",
							color: "#fff",
							textAlign: "center",
						}}
						onClick={() => time_Setup2()}
					>
						확인
					</div>
				</div>
			</div>
		);
	}

	if (canShow) {
		return (
			<div id="master_View" style={{ display: "none" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<p style={{ marginTop: "59px" }}>
						<img alt="logo" src={logo_Img} />
					</p>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							width: "1024px",
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								marginRight: "100px",
							}}
						>
							<div
								style={{
									color: type === "experience" ? "red" : "black",
                                    textDecorationLine: experienceUse === "0" ? "line-through" : null,
									marginRight: "5px",
								}}
								onClick={() => {
                                    if(experienceUse === "1") {
                                        Set_type("experience")
                                    }
                                }}
							>
								무료체험수업
							</div>
							<div
								style={{
									color: type === "cmathday" ? "red" : "black",
                                    textDecorationLine: cmathdayUse === "0" ? "line-through" : null,
                                    marginRight: "5px"
								}}
								onClick={() => {
                                    if(cmathdayUse === "1") {
                                        Set_type("cmathday")
                                    }
                                }}
							>
								시매쓰데이
							</div>
                            <div
								style={{
									color: type === "explain" ? "red" : "black",
                                    textDecorationLine: explainUse === "0" ? "line-through" : null
								}}
								onClick={() => {
                                    if(explainUse === "1") {
                                        Set_type("explain")
                                    }
                                }}
							>
								설명회
							</div>
						</div>

						<div style={{ marginRight: "6px" }}>{academy_Data}</div>
						<img
							alt="setting"
							src={setting_Img}
							className={setup_List.length === 0 ? "setup" : null}
							style={{ width: "auto", height: "auto", margin: "0 6px" }}
							onClick={() => popup_Switch(0)}
						/>
						<img
							alt="add"
							src={add_Img}
							style={{
								opacity: setup_List.length === 0 ? 0 : 1,
								pointerEvents: setup_List.length === 0 ? "none" : null,
								width: "auto",
								height: "auto",
								marginLeft: "6px",
							}}
							onClick={() => popup_Switch(1)}
						/>
					</div>
					{setup_Div()}
					{registration_Div()}
					{modify_Div()}
					{day_Div()}
					{class_Div()}
					{list_Div()}
				</div>
			</div>
		);
	} else {
		return <></>;
	}
}

export default Academy;
