const phone_Num = [
  { value: "010", label: "010" },
  { value: "011", label: "011" },
  { value: "012", label: "012" },
  { value: "016", label: "016" },
  { value: "017", label: "017" },
  { value: "018", label: "018" },
  { value: "019", label: "019" },
];

export default phone_Num;
