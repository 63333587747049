// 시험 접수 페이지
import React, { useEffect, useState } from "react";
import Axios from "axios";
import swal from "sweetalert2";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { compareCode, removeCode } from "../../../../_action/user_action";

// import next from "../image/next.png";
import "./1_Register.css";

// 학교 검색
import SearchBar from "../../SearchBar/SearchBar";
// 학교 데이터
import newdata from "../../SearchBar/schoolList.json";
import Person from "./1_Person";
// import Nav from "../nav";
import phoneArray from "../phone";
import Check from "./2_Check";

import moment from "moment";

import ximg from "../../SearchBar/x.png";

moment.updateLocale("ko", {
  weekdays: ["일", "월", "화", "수", "목", "금", "토"],
});

const mySwal = withReactContent(swal);

const grade_List = [
  { value: "5", label: "5세" },
  { value: "6", label: "6세" },
  { value: "7", label: "7세" },
  { value: "8", label: "초1" },
  { value: "9", label: "초2" },
  { value: "10", label: "초3" },
  { value: "11", label: "초4" },
  { value: "12", label: "초5" },
  { value: "13", label: "초6" },
  { value: "14", label: "중1" },
  { value: "15", label: "중2" },
  { value: "16", label: "중3" },
];

function Register({ setState, socket }) {
  // 학생 이름
  const [name, setName] = useState("");
  // 학교 이름
  const [school, setSchool] = useState("");
  // 주소
  const [address, setAddress] = useState("");
  // 학년
  const [grade, setGrade] = useState();
  // 전화번호
  const [phone, setPhone] = useState("");

  // 전화번호 부분
  const [phone1, setPhone1] = useState({ value: "010", label: "010" });
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");

  // 학교 검색 결과
  const [results, setResults] = useState([]);
  const [recommendResults, setRecommendResults] = useState([]);

  // 개인 정보 동의 여부
  const [person, setPerson] = useState(false);

  // 인증번호 입력창 등장
  const [showAuth, setShowAuth] = useState(false);

  // 입력한 코드값
  const [code, setCode] = useState("");

  // 인증여부
  const [isAuth, setIsAuth] = useState(false);

  // 타이머
  const [timeLimit, setTimeLimit] = useState(0);

  // 에러메시지
  const [nameErr, setNameErr] = useState("");
  const [schoolErr, setSchoolErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [codeErr, setCodeErr] = useState("");

    // 추천 학생 관련
    const [recommend, setRecommend] = useState(true)
    const [recommendName, setRecommendName] = useState("");
    const [recommendSchool, setRecommendSchool] = useState("");
    const [recommendGrade, setRecommendGrade] = useState();

  const dispatch = useDispatch();

  const history = useHistory();

  const academy = useSelector((state) => state.user.academy);
  const classData = useSelector((state) => state.user.classData);

  const type = useSelector((state) => state.user.registerType)

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
    document.head.appendChild(script);

    if (timeLimit > 0) {
      const countdown = setInterval(() => {
        setTimeLimit(timeLimit - 1);
      }, 1000);
      return () => {
        clearInterval(countdown);
      };
    }
    setShowAuth(false);
  }, [timeLimit]);

  if (academy === undefined || classData === undefined) {
    history.goBack();
    return <></>;
  } else {
    const wait = classData.limit > classData.member ? "확정" : "대기";

    // 여기서 부터 학교 검색 부분
    const data = newdata;

    const matchName = (name, keyword) => {
        if(keyword === "") {
            return false;
        }

        return name.indexOf(keyword) > -1;
    };

    const onSearch = (set, text) => {
        const result = data.filter(
            (item) => true === matchName(item.SCHUL_NM, text)
        );

        if(set === '1') {
            setResults(result);
        }
        else if(set === '2') {
            setRecommendResults(result)
        }
    };

    const updateField = (value, update = true) => {
        if(update) {
            onSearch('1', value);
        }

        // const rule = /[^ㄱ-ㅎㅏ-ㅣ가-힝]/g;
        // if(rule.exec(value) !== null) {
        //     document.getElementById("schoolAlert").style.display = "flex";
        // }
        // const result = value.replace(rule, "");
        const result = value;
        setSchool(result);
    };

    const updateField2 = (value, update = true) => {
        if(update) {
            onSearch('2', value);
        }

        // const rule = /[^ㄱ-ㅎㅏ-ㅣ가-힝]/g;
        // if(rule.exec(value) !== null) {
        //     document.getElementById("schoolAlert").style.display = "flex";
        // }
        // const result = value.replace(rule, "");
        const result = value;
        setRecommendSchool(result);
    };
    // 여기까지 학교 검색 부분

    // 다음으로 넘어가기 위한 조건
    const goCheck = async () => {
        if(name.length < 2) {
            mySwal.fire("이름을 확인해주세요.");
        }
        else if(type !== 'explain' && address === "") {
            mySwal.fire("주소를 입력해주세요.");
        }
        else if(type !== 'explain' && school.length === 0) {
            mySwal.fire("학교를 입력해주세요.");
        }
        else if(grade === undefined) {
            mySwal.fire("학년을 입력해주세요.");
        }
        else if(!isAuth) {
            mySwal.fire("전화번호 인증을 해주세요.");
        }
        else if(!person)
            mySwal.fire("개인정보 동의에 동의하셔야만 신청이 가능합니다.");
        else if(type === "cmathday" && recommend && recommendName === "" && recommendSchool === "" && recommendGrade === undefined) {
            mySwal.fire("추천 학생 정보를 입력해 주세요.")
        }
        else {
            check();
        }
    };

    const imgSrc = () => {
      try {
        return require(`../Academy_Image/${academy.academy_Name}_1.jpg`)
          .default;
      } catch (err) {
        return require(`../Academy_Image/academy_img_1.jpg`);
      }
    };

    function korean_Check(e, type) {
    //   const rule = /[^ㄱ-ㅎㅏ-ㅣ가-힝]/g;
    //   let text = "";
    //   if (rule.exec(e.target.value) !== null) {
    //     e.target.value = e.target.value.replace(rule, "");
    //     text = "한글만 입력해주세요.";
    //   }
    //   if (type === "name") setNameErr(text);
    //   if (type === "school") setSchoolErr(text);
    }

    function number_Check(e, type) {
      const rule = /[^0-9]/g;
      let text = "";
      if (rule.exec(e.target.value) !== null) {
        e.target.value = e.target.value.replace(rule, "");
        text = "숫자만 입력해주세요.";
      }
      if (type === "phone") setPhoneErr(text);
      if (type === "code") setCodeErr(text);
    }

    async function smsAuth() {
      let phoneNum = `${phone1.value}${phone2}${phone3}`;
      setPhoneErr("");
      setCodeErr("");
      setCode("");
      if (phoneNum.length < 10) {
        mySwal.fire("전화번호를 정확히 입력해주세요.");
        setTimeLimit(0);
        setShowAuth(false);
        return;
      }
      let checkPhone = await Axios.post("/api/user/check_phone", {
        phone: phoneNum,
        registerType: type
      });
      if (checkPhone.data.check) {
        mySwal.fire("이미 등록된 번호입니다.");
        setTimeLimit(0);
        setShowAuth(false);
        return;
      }
      if (timeLimit > 170) {
        mySwal.fire("잠시 후에 다시 시도해 주세요.");
        return;
      }
      Axios.post("/api/user/make_code", {
        phone: phoneNum,
      }).then((res) => {
        if (res.data.success) {
          setTimeLimit(180);
          setShowAuth(true);
          setPhone(phoneNum);
          //console.log(res.data.code);
          mySwal.fire("인증번호가 발송되었습니다.");
        } else {
          mySwal.fire("인증번호 발송에 실패하였습니다.");
        }
      });
    }

    function confirmAuth() {
      if (code.length < 6) {
        setCodeErr("코드를 정확히 입력해주세요.");
        return;
      }
      const data = { phone: phone, code: code };
      dispatch(compareCode(data)).then((v) => {
        let success = v.payload.success;
        if (success) {
          setIsAuth(true);
          setTimeLimit(0);
          mySwal.fire({
            title: "인증되었습니다.",
            timer: 2000,
          });
          setCodeErr("");
          setCode("");
          dispatch(removeCode({ phone: phone }));
        } else setCodeErr("코드를 확인해주세요.");
      });
    }

    const check = () => {
      mySwal.fire({
        showConfirmButton: false,
        customClass: "check",
        html: (
          <Check
            name={name}
            school={school}
            grade={grade}
            phone={phone}
            address={address}
            academy={academy}
            classData={classData}
            wait={wait}
            dispatch={dispatch}
            socket={socket}
            setState={setState}
            type={type}
            recommend={recommend}
            recommendName={recommendName}
            recommendSchool={recommendSchool}
            recommendGrade={recommendGrade}
          />
        ),
      });
    };

    const phone1Style = {
      container: (provided) => ({
        ...provided,
        width: "50%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }),
      control: (provided) => ({
        ...provided,
        height: "100%",
        border: 0,
        outline: 0,
      }),
      valueContainer: (provided) => ({
        ...provided,
        justifyContent: "center",
      }),
      indicatorSeparator: (defaultStyles) => ({
        ...defaultStyles,
        display: "none",
      }),
      indicatorsContainer: (defaultStyles) => ({
        ...defaultStyles,
        display: "none",
      }),
      menu: (defaultStyles) => ({
        ...defaultStyles,
      }),
      dropdownIndicator: (defaultStyles) => ({
        ...defaultStyles,
        "& svg": { display: "none" },
        "&::before": {
          border: "…",
          transform: "rotate(…)",
        },
        "&::after": {
          border: "…",
          transform: "rotate(…)",
        },
      }),
    };

    function address_Div() {
      return (
        <div
          style={{ position: "relative", width: "100%", marginBottom: "24px" }}
        >
          <div
            onClick={() => setAddress("")}
            style={{
              display: address.length !== 0 ? "flex" : "none",
              position: "absolute",
              right: "5px",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
            }}
          >
            <img
              src={ximg}
              alt=""
              className="address_Cancel"
              style={{
                position: "relative",
                width: "13px",
                height: "13px",
                right: "-5px",
              }}
            ></img>
          </div>
          <input
            type="text"
            id="sample3_address"
            readOnly={true}
            placeholder="주소 검색"
            value={address}
            onClick={() => {
              if (
                document.getElementById("daum_Wrap").style.display === "none"
              ) {
                sample3_execDaumPostcode();
              }
            }}
            style={{ width: "100%" }}
          ></input>

          <div
            id="daum_Wrap"
            style={{
              display: "none",
              border: "1px solid",
              width: "100%",
              height: "300px",
              margin: "5px 0",
              position: "absolute",
              zIndex: 10,
            }}
          >
            <img
              src="//t1.daumcdn.net/postcode/resource/images/close.png"
              id="btnFoldWrap"
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "0px",
                top: "-1px",
                zIndex: "1",
              }}
              onClick={() => foldDaumPostcode()}
              alt="접기 버튼"
            ></img>
          </div>
        </div>
      );
    }

    function foldDaumPostcode() {
      // iframe을 넣은 element를 안보이게 한다.
      document.getElementById("daum_Wrap").style.display = "none";
    }

    function sample3_execDaumPostcode() {
      // 현재 scroll 위치를 저장해놓는다.
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new window.daum.Postcode({
        oncomplete: function (data) {
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ""; // 주소 변수
          var extraAddr = ""; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          setAddress(addr);

          // iframe을 넣은 element를 안보이게 한다.
          // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
          document.getElementById("daum_Wrap").style.display = "none";

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },
        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        // onresize: function (size) {
        //   document.getElementById("daum_Wrap").style.height =
        //     size.height + "px";
        // },
        width: "100%",
        height: "100%",
      }).embed(document.getElementById("daum_Wrap"));

      // iframe을 넣은 element를 보이게 한다.
      document.getElementById("daum_Wrap").style.display = "block";
    }

    let thema = ''
    if(type === 'experience') {
        thema = 'thema1'
    }
    else if(type === 'cmathday') {
        thema = 'thema2'
    }
    else if(type === 'explain') {
        thema = 'thema3'
    }

    return (
      <>
        <div
          className="wrap"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            style={{
              textAlign: "left",
              fontWeight: "700",
              fontSize: "15px",
              margin: "31px 0 4px 0",
              lineHeight: "20px",
            }}
          >
            선택 학원
          </div>
          <div
            style={{
              display: "flex",
              border: "solid 1px #ccc",
              justifyContent: "space-around",
              padding: "10px",
              marginBottom: "24px",
              alignItems: "center",
            }}
          >
            <img
              style={{ maxWidth: "150px" }}
              width="50%"
              height="50%"
              src={imgSrc()}
              alt="img"
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <b>{academy.academy_Name}</b>
              <span>{academy.academy_Address}</span>
              <span style={{ color: "red", marginTop: "5px" }}>
                {classData.grade} /{" "}
                {moment(classData.date).format("MM월 DD일(dddd)")} <br></br>
                {classData.time} {type !== 'explain' && wait === "대기" && `(${wait})`}
              </span>
            </div>
          </div>
          <input
            type="text"
            value={name}
            placeholder="학생 이름"
            style={{ marginBottom: "0" }}
            maxLength={10}
            onInput={(e) => korean_Check(e, "name")}
            onChange={(e) => setName(e.target.value)}
          />
          <div
            style={{
              height: "14px",
              color: "red",
              marginBottom: "10px",
              fontSize: "12px",
              textAlign: "left",
            }}
          >
            {nameErr}
          </div>

          {type !== 'explain' && address_Div()}

          <div>
            <Select
              isSearchable={false}
              value={grade}
              onChange={(v) => setGrade(v)}
              placeholder="학년 선택"
              options={grade_List}
            />
          </div>

          {type !== 'explain' &&
          <>
            <SearchBar
                results={results}
                keyword={school}
                showAddress={true}
                updateField={updateField}
                korean_Check={korean_Check}
                grade={grade?.value}
            />

          <div
            style={{
              height: "14px",
              color: "red",
              marginBottom: "10px",
              fontSize: "12px",
              textAlign: "left",
            }}
          >
            {schoolErr}
          </div>
          </>
          }

          {!isAuth ? (
            <>
              <div className="inputwrapdiv">
                <Select
                  styles={phone1Style}
                  isSearchable={false}
                  options={phoneArray}
                  value={phone1}
                  placeholder=""
                  onChange={(v) => setPhone1(v)}
                />

                {"    - "}
                <input
                  type="text"
                  value={phone2}
                  style={{
                    width: "59%",
                    border: "none",
                    marginBottom: "0",
                    height: "100%",
                    textAlign: "center",
                    padding: 0,
                  }}
                  maxLength={4}
                  onInput={(e) => number_Check(e, "phone")}
                  onChange={(e) => setPhone2(e.target.value)}
                />
                {" - "}
                <input
                  type="text"
                  value={phone3}
                  style={{
                    display: "flex",
                    width: "59%",
                    border: "none",
                    marginBottom: "0",
                    height: "100%",
                    textAlign: "center",
                    padding: 0,
                  }}
                  maxLength={4}
                  onInput={(e) => number_Check(e, "phone")}
                  onChange={(e) => setPhone3(e.target.value)}
                />
                <button
                  onClick={() => smsAuth()}
                  style={{
                    width: "200px",
                    height: "32px",
                    lineHeight: "32px",
                    textAlign: "center",
                    borderRadius: "100px",
                    background: "#C0C0C0",
                    letterSpacing: "-0.3px",
                    border: "none",
                    fontSize: "15px",
                    color: "#fff",
                  }}
                >
                  인증받기
                </button>
              </div>
              <div
                style={{
                  marginTop: "-10px",
                  color: "red",
                  textAlign: "left",
                  marginBottom: "10px",
                  fontSize: "12px",
                  height: "14px",
                }}
              >
                {phoneErr}
              </div>
            </>
          ) : (
            <div className="phoneN">{phone}</div>
          )}
          {showAuth && !isAuth && (
            <>
              <div>
                <div className="inputwrapdiv">
                  <input
                    type="text"
                    style={{
                      width: "75%",
                      border: "none",
                      height: "100%",
                      marginBottom: "0",
                    }}
                    maxLength={6}
                    value={code}
                    placeholder="인증번호 입력"
                    onInput={(e) => number_Check(e, "code")}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <div
                    style={{
                      width: "25%",
                      float: "right",
                      lineHeight: "48px",
                      color: "#E60012",
                    }}
                  >
                    {parseInt(timeLimit / 60)} :{" "}
                    {timeLimit % 60 >= 10
                      ? timeLimit % 60
                      : "0" + (timeLimit % 60)}
                  </div>
                </div>
                <div
                  style={{
                    height: "14px",
                    marginTop: "-10px",
                    color: "red",
                    fontSize: "12px",
                    textAlign: "left",
                  }}
                >
                  {codeErr}
                </div>
                <button
                  className="prevB"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    fontWeight: "400",
                  }}
                  onClick={() => confirmAuth()}
                >
                  인증완료
                </button>
              </div>
            </>
          )}

            {type === "cmathday" &&
                <>
                    <div>
                        <label>
                            <span>추천한 학생 정보 입력</span>
                            <input
                                type="checkbox"
                                checked={recommend}
                                onClick={() => setRecommend(!recommend)}
                            ></input>
                        </label>
                    </div>

                    {recommend &&
                        <div>
                            <input
                                type="text"
                                value={recommendName}
                                placeholder="학생 이름"
                                style={{ marginBottom: "0", width: "100%" }}
                                maxLength={10}
                                onInput={(e) => korean_Check(e, "name")}
                                onChange={(e) => setRecommendName(e.target.value)}
                            />

                            <div
                                style={{
                                    height: "14px",
                                    color: "red",
                                    marginBottom: "10px",
                                    fontSize: "12px",
                                    textAlign: "left",
                                }}
                            >{nameErr}</div>

                            <div>
                                <Select
                                    isSearchable={false}
                                    value={recommendGrade}
                                    onChange={(v) => setRecommendGrade(v)}
                                    placeholder="학년 선택"
                                    options={grade_List}
                                />
                            </div>

                            <SearchBar
                                results={recommendResults}
                                keyword={recommendSchool}
                                showAddress={true}
                                updateField={updateField2}
                                korean_Check={korean_Check}
                                grade={recommendGrade?.value}
                            />

                            <div
                                style={{
                                    height: "14px",
                                    color: "red",
                                    marginBottom: "10px",
                                    fontSize: "12px",
                                    textAlign: "left",
                                }}
                            >{schoolErr}</div>
                        </div>
                    }
                </>
            }

          <Person person={person} setPerson={setPerson} type={type} />

          <div style={{ margin: "50px 0px 200px 0px" }}>
            <button className="prevB" onClick={() => history.goBack()}>
              이전
            </button>
            <button
                className={`nextB ${thema}`}
                style={{
                    marginLeft: "1%",
                }}
                onClick={() => goCheck()}
            >
              다음
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default Register;
