//학원 선택 페이지
import React from "react";
import { useSelector } from "react-redux";
import checked from "../image/checked.png";

function Academy_Select({
  regionSelect,
  secondRegion,
  secondRegionSelect,
  thirdRegion,
  changeSecondData,
  chooseAcademy,
}) {
  const academy = useSelector((state) => state.user.academy);
  return (
    <>
      <h3>학원검색</h3>
      <div className="gu" style={{ display: "flex" }}>
        <div>
          {regionSelect !== "" &&
            secondRegion.map((v, i) => (
              <div
                key={i}
                className={`menu-item ${
                  secondRegionSelect === v ? "active" : ""
                }`}
                onClick={() => changeSecondData(v)}
              >
                {v}
              </div>
            ))}
        </div>
        <div>
          {secondRegionSelect !== "" ? (
            thirdRegion.map((v, i) => (
              <div
                className="menu-item"
                key={i}
                onClick={() => chooseAcademy(v)}
              >
                {v}
                {academy && academy.academy_Name === v && (
                  <img
                    src={checked}
                    alt=""
                    style={{
                      width: "28px",
                      float: "right",
                      marginTop: "12px",
                      paddingRight: "10px",
                    }}
                  />
                  /*<strong style={{ float: "right" }}>✔</strong>*/
                )}
              </div>
            ))
          ) : (
            <p
              style={{
                fontSize: "15px",
                fontWeight: "700",
                lineHeight: "40px",
                paddingLeft: "22px",
                textAlign: "left",
              }}
            >
              지역을 선택해주세요.
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default Academy_Select;
