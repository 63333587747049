import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePage } from "../../../_action/user_action";
import logo from "./image/logo.png";
import prev from "./image/prev.png";

function Nav(set, state) {
    const type = useSelector((state) => state.user.registerType);

  const page = useSelector((state) => state.user.page);
  const academy = useSelector((state) => state.user.academy);
  const dispatch = useDispatch();

  const changeFunc = () => {
    if (state === 2) return window.history.go(-1);
    else if (state === 1 && page === 1) return dispatch(changePage(0));
    else if (state === 1 && page === 0 && academy)
      return dispatch(changePage(1));

    const this_path = "/" + window.location.pathname.split("/")[2]
    return (window.location.href = window.location.pathname.replace(this_path, ""));
  };
  return (
    <nav
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {set !== "none" && (
        <img
          src={prev}
          onClick={() => changeFunc()}
          style={{ position: "absolute", left: "3%", cursor: "pointer" }}
          alt="prev"
        />
      )}

      <img
        src={logo}
        alt="logo"
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = "/" + type;
        }}
      ></img>
    </nav>
  );
}

export default Nav;
