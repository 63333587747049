// 내역 확인 페이지(팝업)
import React from "react";
import Axios from "axios";
// import next from "../image/next.png";
import "./2_Check.css";

import * as set from "../../Cmath/counting";
import swal from "sweetalert2";
import moment from "moment";
import { setWait } from "../../../../_action/user_action";

function Check({ name, school, grade, address, phone, academy, classData, setState, wait, dispatch, socket, type, recommend, recommendName, recommendSchool, recommendGrade }) {
    
	const save = () => {
		const value = {
			name: name,
			school: school,
			grade: grade.value,
			address: address,
			phone: phone,
			classData: classData,
            registerType: type
		};

        if(type === "cmathday" && recommend) {
            value['recommend'] = recommend
            value['recommendName'] = recommendName
            value['recommendSchool'] = recommendSchool
            value['recommendGrade'] = recommendGrade.value
        }
        
		Axios.post("/api/user/save", value).then((res) => {
			if (res.data.success) {
				// socket.emit("getData", academy.academy_Name);
				// socket.emit("reservation_Data", academy.academy_Name);
				dispatch(setWait(wait));
				swal.clickConfirm();
				socket.emit("getData", academy.academy_Name);
				setState(3);
			} else {
				alert("에러");
			}
		});
	};

    let thema = ''
    if(type === 'experience') {
        thema = 'thema1'
    }
    else if(type === 'cmathday') {
        thema = 'thema2'
    }
    else if(type === 'explain') {
        thema = 'thema3'
    }

	return (
		<div className="result">
			<p>신청 확인</p>
			<div className="box">
				<div>
					<span>이름</span>
					<p>{name}</p>
				</div>
                {type !== 'explain' &&
				<div>
					<span>{["5", "6", "7"].includes(grade.value) ? '유치원' : '학교'}</span>
					<p>{school}</p>
				</div>
                }
				<div>
					<span>학년</span>
					<p>{grade.label}</p>
				</div>
                {type !== 'explain' &&
				<div>
					<span>주소</span>
					<p>{address}</p>
				</div>
                }
				<div>
					<span>전화번호</span>
					<p>{phone}</p>
				</div>
				<div>
					<span>학원</span>
					<p>{academy.academy_Name}</p>
				</div>
				<div>
					<span>{type === "cmathday" || type === 'explain' ? "일자" : "수업일자"}</span>
					<p>{moment(classData.date).format("YYYY.MM.DD")}</p>
				</div>
				<div>
					<span>{type === "cmathday" || type === 'explain' ? "시간" : "수업시간"}</span>
					<p style={{ color: "red" }}>
						{classData.time}
						{wait === "대기" && "(대기)"}
					</p>
				</div>
			</div>

            {type === "cmathday" && recommend &&
            <>
                <p>추천 학생 정보</p>
                <div className="box">
                    <div>
                        <span>이름</span>
                        <p>{recommendName}</p>
                    </div>

                    <div>
                        <span>{["5", "6", "7"].includes(grade.value) ? '유치원' : '학교'}</span>
                        <p>{recommendSchool}</p>
                    </div>

                    <div>
                        <span>학년</span>
                        <p>{recommendGrade.label}</p>
                    </div>
                </div>
            </>
            }
            {type === "cmathday" && !recommend &&
                <div className="box">추천한 학생이 없습니다.</div>
            }

			<div style={{ marginTop: "44px" }}>
				<button
					style={{ height: "40px", lineHeight: "40px", width: "49%" }}
					className="prevB"
					onClick={() => swal.clickCancel()}
				>
					취소
				</button>
				<button
					style={{
						marginLeft: "2%",
						height: "40px",
						lineHeight: "40px",
						width: "49%",
					}}
					className={`nextB ${thema}`}
					onClick={() => {
						set.counting("completion");
						save();
					}}
				>
					접수
				</button>
			</div>
		</div>
	);
}

export default Check;
