import React, { useEffect, useState } from "react";
// import logo from "./image/logo.png";
import "./LandingPage.css";

import * as set from "../Cmath/counting";

// import main from './image/main.png'

import Nav from "./nav";
import Slider from "react-slick";
import First from "./1_First";
import Second from "./2_Second";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function LandingPage({ type }) {

	const [playable, setPlayable] = useState(false);
	const settings = {
		infinite: false,
		speed: 300,
		arrows: false,
		vertical: true,
		verticalSwiping: true,
		easing: "ease-in",
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		beforeChange: (current) => {
			if (current === 1 && playable) document.getElementById("hz").pause();
		},
		afterChange: (current) => {
			if (current === 1) {
				set.counting("content");
				if (playable) document.getElementById("hz").play();
			}
		},
	};

	useEffect(() => {
		set.counting("access");
	}, []);

	return (
		<div style={{ height: "100%" }}>
			{Nav("none")}
			{/* <Slider {...settings}> */}
			<First />
			{/* <Second playable={playable} setPlayable={setPlayable} /> */}
			{/* </Slider> */}
		</div>
	);
}

export default LandingPage;
