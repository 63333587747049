import {
	CHANGE_PAGE,
	COMPARE_CODE,
	INSERT_CLASS,
	REMOVE_CODE,
	SELECT_ACADEMY,
	SET_TYPE,
	SET_WAIT,
} from "../_action/types";

const reducer = function (state = { page: 0 }, action = {}) {
	switch (action.type) {
		case COMPARE_CODE:
			return { ...state, ...action.payload };
		case REMOVE_CODE:
			return { ...state, ...action.payload };
		case SET_TYPE:
			return { ...state, registerType: action.registerType };
		case SET_WAIT:
			return { ...state, wait: action.wait };
		case CHANGE_PAGE:
			return { ...state, page: action.page };
		case SELECT_ACADEMY:
			return {
				...state,
				academy: action.academy,
			};
		case INSERT_CLASS:
			return {
				...state,
				classData: action.classData,
			};
		default:
			return state;
	}
};

export default reducer;
