//학원 선택 전체 페이지
import axios from "axios";
import React, { useEffect, useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { useDispatch, useSelector } from "react-redux";
import { changePage, selectAcademy } from "../../../../_action/user_action";

import next from "../image/next.png";

import Nav from "../nav";

import "./0_Academy.css";
import AcademyDetail from "./0_Academy_Detail";
import AcademySelect from "./0_Academy_Select";

const region = [
  "서울",
  "경기",
  "인천",
  "강원",
  "충남",
  "대전",
  "충북",
  "세종",
  "부산",
  "울산",
  "대구",
  "경북",
  "경남",
  "전남",
  "광주",
  "전북",
  "제주"
];

function Academy({ socket, type }) {
  // 지역 리스트(시도)
  const [list, setList] = useState([]);
  // 첫 지역 선택 후 두 번째 지역 분류(시군구)
  const [secondRegion, setSecondRegion] = useState([]);
  // 두 번째 지역 선택 후 학원 분류
  const [thirdRegion, setThirdRegion] = useState([]);
  // 선택한 지역(시도)
  const [regionSelect, setRegionSelect] = useState("");
  // 선택한 두 번째 지역(시군구)
  const [secondRegionSelect, setSecondRegionSelect] = useState("");

  // 0이면 지역 선택페이지, 1이면 시간 선택 페이지
  const page = useSelector((state) => state.user.page);

  const dispatch = useDispatch();

  useEffect(() => {
    axios
    .post("/api/academy/academy_Load", {
        type: type
    })
    .then((res) => {
      setList(res.data);
      let a = Array.from(
        new Set(
          res.data
            .filter((k) => k.academy_Region === "서울")
            .map((v) => v.academy_Region2)
        )
      ).sort();
      setSecondRegion(a);
    });

    setRegionSelect("서울");
  }, []);

  const changeData = (v) => {
    let a = Array.from(
      new Set(
        list.filter((k) => k.academy_Region === v).map((v) => v.academy_Region2)
      )
    ).sort();
    setSecondRegionSelect("");
    setThirdRegion([]);
    setSecondRegion(a);
    setRegionSelect(v);
  };

  const changeSecondData = (v) => {
    let a = Array.from(
      new Set(
        list
          .filter(
            (k) => k.academy_Region === regionSelect && k.academy_Region2 === v
          )
          .map((v) => v.academy_Name)
      )
    ).sort();
    setThirdRegion(a);
    setSecondRegionSelect(v);
  };

  const chooseAcademy = (v) => {
    let a = list.find((k) => k.academy_Name === v);
    dispatch(selectAcademy(a));
    dispatch(changePage(1));
  };

  function check_Data(v) {
      if(list.filter((k) => k.academy_Region === v).length === 0) {
          return "none"
      }
      else {
          return null
      }
  };

  let thema = ''
  if(type === 'experience') {
      thema = 'thema1'
  }
  else if(type === 'cmathday') {
      thema = 'thema2'
  }
    else if(type === 'explain') {
        thema = 'thema3'
    }

  return (
    <>
      {Nav("", 1, null)}

      <div
        className={thema}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          height: "7vh",
        }}
      >
        <span className="now">01 학원선택</span>
        <img src={next} style={{ marginTop: "0" }} alt="next" />
        <span className="wait">02 정보입력</span>
        <img src={next} style={{ marginTop: "0" }} alt="next" />
        <span className="wait">03 신청완료</span>
      </div>

      <div>
        <div>
          <ScrollMenu
            data={region.map((v, i) => (
              <div
                key={i}
                className={`menu-item ${regionSelect === v ? "active" : ""}`}
                style={{display:check_Data(v)}}
                onClick={() => {
                  dispatch(changePage(0));
                  changeData(v);
                }}
              >
                {v}
              </div>
            ))}
            alignCenter={false}
          />
          {page === 0 && (
            <AcademySelect
              regionSelect={regionSelect}
              secondRegion={secondRegion}
              secondRegionSelect={secondRegionSelect}
              thirdRegion={thirdRegion}
              changeSecondData={changeSecondData}
              chooseAcademy={chooseAcademy}
            />
          )}
          {page === 1 && <AcademyDetail socket={socket} />}
        </div>
      </div>
    </>
  );
}

export default Academy;
