import React, { useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";

import UserPage from "./views/User/LandingPage";
import SelectPage from "./views/User/AcademyPage/0_Academy_Page";
import RegisterPage from "./views/User/RegisterPage/RegisterPage";
import CheckPage from "./views/User/CheckPage/CheckPage";
import { setType } from "../_action/user_action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";

const helmetData = {
	experience: {
		title: "시매쓰 무료 체험수업 신청",
		description: "체험수업은 실제 수업과 동일한 방식으로 이루어집니다.",
		img: "https://event.cmath.co.kr/og_experience.jpg",
	},
	cmathday: {
		title: "시매쓰 시매쓰데이 신청",
		description: "유초등 4~6월 수학과 손잡고 친구랑 놀자",
		img: "https://event.cmath.co.kr/og_cmathday.jpg",
	},
    explain: {
        title: "시매쓰 설명회 신청",
        description: "서류 'ZERO' 입시 시대, 선행만으로는 안된다 - 초등부터 준비할 것",
        img: "https://event.cmath.co.kr/og_explain.jpg"
    }
};

function Routers({ registerType, socket }) {
	const dispatch = useDispatch();

	useLayoutEffect(() => {
		dispatch(setType(registerType));
	}, []);

	return (
		<>
			<Helmet>
				<title>{helmetData[registerType].title}</title>
				<meta name="description" content={helmetData[registerType].description} />
				<meta name="theme-color" content="#000000" />
				<meta property="og:description" content={helmetData[registerType].description} />
				<meta property="og:title" content={helmetData[registerType].title} />
				<meta property="og:image" content={helmetData[registerType].img} />
			</Helmet>
			<Switch>
				<Route exact path={`/${registerType}`} render={() => <UserPage type={registerType} />} />
				<Route
					exact
					path={`/${registerType}/select`}
					render={() => <SelectPage socket={socket} type={registerType} />}
				/>
				<Route
					exact
					path={`/${registerType}/register`}
					render={() => <RegisterPage socket={socket} type={registerType} />}
				/>
				<Route exact path={`/${registerType}/check`} render={() => <CheckPage type={registerType} />} />
			</Switch>
		</>
	);
}

export default Routers;
