// 학원 선택 후 시간대 선택 페이지
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import swal from "sweetalert2";
import Slider from "react-slick";
import { changePage, insertClass } from "../../../../_action/user_action";
import axios from "axios";
import "./0_Academy_Detail.css";

import { useHistory } from "react-router-dom";

import call from "../Academy_Image/call.png";
import map from "../Academy_Image/map.png";

moment.updateLocale("ko", {
	weekdays: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
});

const grade_List = [
	"5세",
	"6세",
	"7세",
	"1학년",
	"2학년",
	"3학년",
	"4학년",
	"5학년",
	"6학년",
	"유아(Wini)",
	"초등저학년(NC)",
	"초등고학년(AP)",
];

function Academy_Detail({ socket }) {
	const dispatch = useDispatch();
	const academy = useSelector((state) => state.user.academy);

	const type = useSelector((state) => state.user.registerType);

	const history = useHistory();

	// 학원 내 반 리스트
	const [classList, setclassList] = useState({});
	// 선택한 시간의 반 정보
	const [getObj, setGetObj] = useState({});
	// 반이 없을 때 메시지
	const [msg, setMsg] = useState("");

	useEffect(() => {
		axios
			.post("/api/class/getData", {
				academy: academy.academy_Name,
				type: type,
			})
			.then((res) => {
				let result = {};
				if (res.data.success) {
					let sortData = res.data.count.sort((a, b) =>
						a.date < b.date ? -1 : a.date > b.date ? 1 : a.time < b.time ? -1 : a.time > b.time ? 1 : 0
					);
					sortData.forEach((o) => {
						let obj = {
							_id: o._id,
							grade: grade_List[o.grade - 5],
							time: o.time,
							date: o.date,
							limit: o.limit,
							member: o.member.length,
							unused: o.unused,
						};
						if (result[o.date]) result[o.date].push(obj);
						else result[o.date] = [obj];
					});
				} else setMsg("현재 학원은 등록된 반이 없습니다.");
				setclassList(result);
			});
	}, [academy]);

	useEffect(() => {
		socket.connect();
		socket.emit("joinRoom", academy.academy_Name);
		socket.emit("getData", academy.academy_Name);
		socket.on("FromAca", (value) => {
			let result = {};
			let filtered = value.filter((v) => v.type === type && moment().isBefore(v.date));
			filtered.forEach((o) => {
				let obj = {
					_id: o._id,
					grade: grade_List[o.grade - 5],
					time: o.time,
					date: o.date,
					limit: o.limit,
					member: o.member.length,
					unused: o.unused,
				};
				if (result[o.date]) result[o.date].push(obj);
				else result[o.date] = [obj];
			});
			setclassList(result);
		});
	}, [socket, academy.academy_Name, type]);

	const goNext = () => {
		if (Object.keys(getObj).length !== 0) {
			dispatch(insertClass(getObj));
			history.push(`/${type}/register`);
		} else {
			swal.fire("시간을 선택해주세요.");
		}
	};

	const setMargin = (key) => {
		switch (key) {
			case 0:
				return "0px 10px 8px 0";
			default:
				return "0px 10px 8px 0";
		}
	};

	const imgSrc = (num) => {
		try {
			return require(`../Academy_Image/${academy.academy_Name}_${num}.jpg`);
		} catch (err) {
			return require(`../Academy_Image/academy_img_${num}.jpg`);
		}
	};

    let thema = ''
    if(type === 'experience') {
        thema = 'thema1'
    }
    else if(type === 'cmathday') {
        thema = 'thema2'
    }
    else if(type === 'explain') {
        thema = 'thema3'
    }

	return (
		<div className="academy_warp">
			<div id="imgContainer">
				<div id="imgSlider">
					<Slider className="acaImg" dots={true} arrows={false} cssEase="linear">
						<img alt="img1" style={{ width: "200px" }} src={imgSrc(1)} />
						<img alt="img2" style={{ width: "200px" }} src={imgSrc(2)} />
						<img alt="img3" style={{ width: "200px" }} src={imgSrc(3)} />
					</Slider>
				</div>
				<div className="btnswrap_2" style={{ display: "flex", flexDirection: "column" }}>
					<b style={{ fontSize: "19px", fontWeight: "700" }}>{academy.academy_Name}</b>
					<span style={{ fontSize: "17px", color: "#878787", marginTop: "5px" }}>{academy.academy_Address}</span>
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
							marginTop: "20px",
						}}
					>
						<a className="call" href={`tel:${academy.academy_Phone}`}>
							<button
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<img alt="call" src={call} />
								{academy.academy_Phone}
							</button>
						</a>
						<a
							target="_blank"
							className="map"
							rel="noreferrer"
							href={`https://map.kakao.com/link/map/시매쓰 ${academy.academy_Name},${academy.academy_Y},${academy.academy_X}`}
						>
							<button
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<img alt="map" src={map} style={{ marginRight: "5px" }} />
								지도 보기
							</button>
						</a>
					</div>
				</div>
			</div>
			{Object.keys(classList).length > 0 ? (
				<>
					<p style={{ fontSize: "15px", fontWeight: "700", textAlign: "left" }}>시간선택</p>
					{Object.keys(classList).map((v) => {
						let date = moment(v).format("YYYY.MM.DD(dddd)");
						return (
							<div key={v} style={{ textAlign: "left", marginBottom: "40px" }} className="selectClass">
								<div style={{ marginBottom: "16px" }}>{date}</div>
								<div
									style={{
										display: "flex",
										textAlign: "center",
										justifyContent: "flex-start",
										flexWrap: "wrap",
									}}
								>
									{classList[v].map((a, key) => {
										let m = a.limit - a.member;
										return (
											<div style={{ margin: setMargin(key) }} key={key}>
												<input
													type="radio"
													name="selected"
													disabled={a.unused}
													id={`radio${date + key}`}
													onClick={() => setGetObj(a)}
													className="selected"
												/>
												<label
													htmlFor={`radio${date + key}`}
													className="timeSelect"
													style={{
														backgroundColor: a.unused ? "#ccc" : "",
														color: a.unused ? "#959595" : "",
													}}
												>
													<b>{a.time}</b>
													<b>{a.grade}</b>
												</label>

												{a.limit > a.member ? (
													<div>
														잔여: <span style={{ color: "red" }}>{m < 10 ? "0" + m : m}명</span>
													</div>
												) : (
													<div
														style={{
															backgroundColor: "#f07300",
															color: "white",
														}}
													>
														대기신청 가능
													</div>
												)}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
					<div className="btnwrap" style={{ paddingBottom: "200px" }}>
						<button className="prevB" onClick={() => dispatch(changePage(0))}>
							이전
						</button>
						<button
							className={`nextB ${thema}`}
							style={{
								marginLeft: "1%",
							}}
							onClick={() => goNext()}
						>
							다음
						</button>
					</div>
				</>
			) : (
				<b style={{ fontSize: "22px" }}>{msg}</b>
			)}
		</div>
	);
}

export default Academy_Detail;
