// 개인정보 동의 항목
import React, { useState } from "react";
import "./1_Person.css";
import drop from "../image/drop.png";
import up from "../image/up.png";

function Person({ person, setPerson, type }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <div className="control-group">
          <label className="control control--checkbox" htmlFor="check">
            개인정보 동의
            <input
              type="checkbox"
              id="check"
              value={person}
              onClick={() => setPerson(!person)}
            />
            <button className="more" onClick={() => setOpen(!open)}>
              {!open ? <img src={drop} alt="" /> : <img src={up} alt="" />}
            </button>
            <div className="control__indicator"></div>
          </label>
        </div>
      </div>
      {open && (
        <div className="agree">
          수집하는 개인정보의 항목
          <br></br>
            {type === 'explain'
            ? <span>▶ 필수항목 : 이름, 연락처(학부모)</span>
            : <span>▶ 필수항목 : 이름, 주소, 학교, 연락처(학부모)</span>
            }
          <br></br>
          <br></br>
          개인정보의 수집 및 이용 목적
          <br></br>
            {type === 'explain'
            ? <span>▶ 이름, 연락처(학부모) : 서비스 이용에 따른</span>
            : <span>▶ 이름, 주소, 학교, 연락처(학부모) : 서비스 이용에 따른</span>
            }
          민원사항의 처리를 위한 본인식별을 위하여 사용됩니다.
          <br></br>
          <br></br>
          개인정보의 보유 및 이용기간
          <br></br>
          회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
          정보를 지체없이 파기합니다. 단, 관계법령의 규정에 의하여 보존할 필요가
          있는 경우 회사는 관계법령이 정한 일정한 기간 동안 회원정보를
          보관합니다.
          <br></br>▶ 계약 또는 청약철회 등에 관한 기록: 5년
          <br></br>▶ 대금결제 및 재화 등의 공급에 관한 기록: 5년
          <br></br>▶ 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
          <br></br>▶ 방문에 관한 기록 : 3개월
          <br></br>▶ 보유기간을 이용자에게 미리 고지하거나 개별적으로 이용자의
          동의를 받은 경우: 고지하거나 개별 동의한 기간
          <br></br>▶ 수탁자 : (주)오메가 포인트
          <br></br>
          위탁업무내용 : 고객상담, 부정이용방지
          <br></br>
          위탁기간 : 위탁 계약 종료 시까지
        </div>
      )}
    </>
  );
}

export default Person;
